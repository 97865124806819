// If you are using JavaScript/ECMAScript modules:
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";

Dropzone.autoDiscover = false;
initializeFileDropzone();
initializeImageDropzone();

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    initializeFileDropzone();
    initializeImageDropzone();
});

function initializeDropzone(selector, inputName, useTimeInFilename) {

    $(selector + ' .drop').each(function () {
        var dropzoneDiv = $(this).parents(selector).eq(0);
        var dropzoneArea = dropzoneDiv.find('.dropzone').eq(0);
        var uploadButton = dropzoneDiv.find('.uploadbutton').eq(0);

        let link = dropzoneDiv.data('uploadlink');
        let acceptedFileTypes = dropzoneDiv.data('dropzone-accepts');
        if (acceptedFileTypes == '') {
            acceptedFileTypes = 'image/*';
        }

        var myDropzone = dropzoneArea.dropzone(
                {
                    url: link,
                    method: "POST",
                    clickable: uploadButton[0],
                    maxFiles: 20,
                    uploadMultiple: true,
                    parallelUploads: 1,
                    resizeWidth: 1250,
                    resizeQuality: 0.95,
                    thumbnailWidth: 115,
                    thumbnailHeight: 115,
                    acceptedFiles: acceptedFileTypes,
                    maxThumbnailFilesize: 30,
                    autoProcessQueue: true,
                    addRemoveLinks: true,
                    renameFile: function (file) {
                        let newName = useTimeInFilename ? (new Date().getTime() + '_' + file.name) : file.name;
                        return newName;
                    },
                    init: function () {
                        this.on("addedfile", function (file) {
                            let newName = useTimeInFilename ? (new Date().getTime() + '_' + file.name) : file.name;
                            file.previewElement.querySelector('[data-dz-name]').textContent = file.upload.filename;

                            let sorthandle = document.createElement('span');
                            sorthandle.classList.add('handlep');
                            let arrow = document.createElement('i');
                            arrow.classList.add("fas", "fa-arrows-alt");
                            sorthandle.appendChild(arrow);
                            file.previewElement.appendChild(sorthandle);
                        });

                        this.on('completemultiple', function (file, json) {
                            $('.ui-sortable').sortable('serialize');
                        });

                        /*this.on("uploadprogress", function (file, progress) {
                         console.log("File progress", progress);
                         });*/
                        this.on("success", function (file, response) {
                            $(file.previewTemplate).append($('<input type="hidden" ' + 'name="' + inputName + '" ' + 'value="' + file['upload']['filename'] + '">'));
                        });
                        this.on("error", function (file, message) {
                            console.log(message);
                           $(file.previewElement).addClass("dz-error").find('.dz-error-message').text(message.Message);
                        });
                    },
                    removedfile: function (file) {
                        var name = file.upload.filename;
                        $.ajax({
                            type: 'POST',
                            url: link,
                            data: {name: name, request: 2},
                            sucess: function (data) {
                                console.log('success: ' + data);
                            }
                        });
                        var _ref;
                        return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                    },
                });

        /*myDropzone.on('success', function (file, response) {
         var fileup = JSON.parse(response);
         $(file.previewTemplate).append($('<input type="hidden" ' + 'name="files[]" ' + 'value="' + fileup[0] + '">'));
         });*/

        myDropzone.on("maxfilesexceeded", function (file) {
            this.removeFile(file);
        });

        $(this).click(function (element) {
            let dropzoneControl = $(this)[0].dropzone;
            if (dropzoneControl) {
                dropzoneControl.destroy();
            }

            dropzoneDiv.find('.dropzoneods').removeClass('d-flex').hide();
            dropzoneDiv.find('.dropzone').hide();
            dropzoneDiv.find('.dz-default').hide();
            dropzoneDiv.find('.fallback').removeClass('d-none').show();
        });

    });
}

$(function () {
    $(".dropzone").sortable({
        items: '.dz-preview',
        handle: '.handlep',
        cursor: 'move',
        opacity: 0.5,
        containment: '.dropzone',
        distance: 20,
        tolerance: 'pointer'
    });
});

function initializeFileDropzone() {
    let selector = '.dropzone-file-upload';
    initializeDropzone(selector, 'uploaderFiles[]', false);
}
function initializeImageDropzone() {
    let selector = '.dropzone-image-upload';
    initializeDropzone(selector, 'uploaderImages[]', true);
}
