import cookieconsent from 'vanilla-cookieconsent';
import "vanilla-cookieconsent/dist/cookieconsent.css"
var cc = initCookieConsent();

const htmlLang = document.documentElement.lang.substring(0, 2).toLowerCase();
switch (htmlLang) {
    case 'en':
        cc.loadScript('/www/js/cookieconsent/translations/en.js', runPlugin);
        break;
    case 'cs':
        cc.loadScript('/www/js/cookieconsent/translations/cs.js', runPlugin);
        break;
    default:
        cc.loadScript('/www/js/cookieconsent/translations/en.js', runPlugin);
}

function runPlugin() {
// run plugin with your configuration
    cc.run({
        auto_language: true,
        current_lang: htmlLang,
        autoclear_cookies: true,
        page_scripts: true,
        autorun: true,
        mode: "opt-in",
        force_consent: true,
        "categories": [
            "necessary",
            "analytics",
            "targeting"
        ],
        "level": [
            "necessary",
            "analytics",
            "targeting"
        ],
        "revision": 0,
        "data": null,
        "rfc_cookie": false,
        "consent_date": "2023-01-08T11:59:44.480Z",
        "consent_uuid": "9d8e5aff-58ee-49c6-a7a1-87798cfe3198",
        "last_consent_update": "2023-01-08T11:59:46.200Z",

        // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        // delay: 0,                               // default: 0
        // auto_language: null                     // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: false,                   // default: false
        // hide_from_bots: true,                   // default: true
        // remove_cookie_tables: false             // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        onFirstAction: function (user_preferences, cookie) {
            // callback triggered only once
        },

        onAccept: function (cookie) {
            // ...
        },

        onChange: function (cookie, changed_preferences) {
            // ...
        },
        languages: window.ccTranslations,
        gui_options: {
            consent_modal: {
                layout: 'bar', // box/cloud/bar
                position: 'bottom center', // bottom/middle/top + left/right/center
                transition: 'slide', // zoom/slide
                swap_buttons: false            // enable to invert buttons
            },
            settings_modal: {
                layout: 'box', // box/bar
                // position: 'left',           // left/right
                transition: 'zoom'            // zoom/slide
            }
        }
    });
}
